<template>
  <div>  
<personnel_bar/>    


    <v-container fluid>
      <base-material-card
        icon="mdi-clipboard-text"
        title="ข้อมูลประเภทสถานศึกษา"
        class="px-5 py-3"
      >
        <v-card class="mb-4 pa-2">
          <v-row no-gutters>
            <v-col cols="12" md="8" class="text-right pa-1">
              <v-text-field
                v-model="search"
                append-icon="mdi-magnify"
                label="ค้นหา ระบุคำ หรือ ส่วนข้อความเกี่ยวข้อง"
                single-line
                hide-details
                dense
                filled
                class="mb-2"
              />
            </v-col>       
            <v-col cols="12" md="4" class="text-right pa-1">
              <v-btn
              block
                large
                right
                depressed
                color="primary"
                @click.native="jor18Add()"
              >
                <v-icon>mdi-plus-circle-outline</v-icon>เพิ่มรายการ
              </v-btn>
            </v-col>
            <v-col cols="12" md="4" class="pa-1">
              <v-autocomplete
                v-model="collegesSelect"
                :items="colleges"
                item-text="college_name"
                item-value="college_code"
                outlined
                label="ค้นหาวิทยาลัย"
                @change="jor18sSearch()"
              ></v-autocomplete>
            </v-col>
            <v-col cols="12" md="4" class="pa-1">
              <v-text-field
                v-model="idPositionSelect"               
                outlined
                label="ค้นหาเลขที่ตำแหน่ง"
                @keypress.enter="jor18sIDpostionSearch()"
              >          
            </v-text-field>
            </v-col>
            <v-col cols="12" md="4" class="pa-1">
              <v-btn
              block
                large
                right
                depressed
                color="warning"
                @click.native="updatej18background()"
              >
                <v-icon>mdi-pencil</v-icon>ปรับปรุงอัตรากำลังรับย้ายครู
              </v-btn>

              <v-btn
              block
                large
                right
                depressed
                color="primary"
                @click.native="SearchNull()"
              >
                <v-icon>mdi-search-web</v-icon>ตำแหน่งว่าง
              </v-btn>

              <v-btn
              block
                large
                right
                depressed
                color="info"
                @click.native="clearSearch()"
              >
                <v-icon>mdi-search-web</v-icon>ล้างการค้นหา
              </v-btn>

              <v-btn
              block
                large
                right
                depressed
                color="info"
                href="#/admin/print_jor18_null/"
                target="_blank"
              >
                <v-icon>mdi-printer</v-icon>ส่งออกตำแหน่งว่าง
              </v-btn>

            </v-col>

          </v-row>
        </v-card>
        <v-data-table
          color="success"          
          :headers="headers"
          :items="jor18s"
          :search="search"
        >

        

        <template v-slot:item.ptdel_status_del="{ item }">
            <v-chip dark color="warning" v-if="item.ptdel_status_del==='resign'">
              เลขว่างลาออก
            </v-chip>

            <v-chip dark color="warning" v-else-if="item.ptdel_status_del==='discard'">
              เลขว่างให้ออก
            </v-chip>
            <v-chip dark color="warning" v-else-if="item.ptdel_status_del==='dismiss'">
              เลขว่างไล่ออก
            </v-chip>

            <v-chip dark color="primary" v-else-if="item.ptdel_status_del==='transfer'">
              เลขว่างโอนย้าย
            </v-chip>

            <v-chip dark color="primary" v-else-if="item.ptdel_status_del==='retire'">
              เลขว่างเกษียณ
            </v-chip>

            <v-chip dark color="info" v-else-if="item.ptdel_status_del==='died'">
              เลขว่างเสียชีวิต
            </v-chip>

            <v-chip dark color="info" v-else-if="item.ptdel_status_del==='disclaim'">
              เลขว่างสละสิทธิ์ไม่รายงานตัว
            </v-chip>
       
          </template>


          <template v-slot:item.status_booking="{ item }">
            <v-chip dark color="warning" v-if="item.status_booking">
              {{ item.status_booking }}
            </v-chip>
            <v-chip dark color="green" v-else>
              ว่าง
            </v-chip>
          </template>

          <template v-slot:item.actions="{ item }">
            <v-icon color="yellow" @click.stop="jor18Edit(item.id_position,item.positions)">
              mdi-pencil
            </v-icon>
          </template>
          <template v-slot:item.action_s="{ item }">
            <v-icon color="red" @click.stop="jor18Delete(item.id_position,item.positions)">
              mdi-delete
            </v-icon>
          </template>
          <v-alert
            slot="no-results"
            :value="true"
            color="error"
            icon="mdi-alert"
            >ไม่พบผลลัพธ์ "{{ search }}" ที่คุณกำลังค้นหา.</v-alert
          >
        </v-data-table>
      </base-material-card>

      <!--addjor18dialog  -->
      <v-layout row justify-center>
        <v-dialog v-model="addjor18dialog" persistent max-width="50%">
          <v-card class="mx-auto pa-5">
            <base-material-card
              icon="mdi-account-multiple"
              title="เพิ่มข้อมูลประเภทสถานศึกษา"
              class="px-5 py-3"
            >
            </base-material-card>

            <v-card-text>
              <v-form ref="addjor18form" lazy-validation>
                <v-container grid-list-md>
                  <v-layout wrap>
                    <v-flex md12>
                      <v-autocomplete
                        :items="colleges"
                        item-text="college_name"
                        item-value="college_code"
                        v-model="addjor18.j18college_code"
                        label="วิทยาลัย"
                        required
                        :rules="[v => !!v || '']"
                      >
                      </v-autocomplete>
                    </v-flex>
                    <v-flex md12>
                      <v-text-field
                        label="เลขที่ตำแหน่ง"
                        v-model="addjor18.id_position"
                        required
                        :rules="[v => !!v || '']"
                      ></v-text-field>
                    </v-flex>
                    <v-flex md12>
                      <v-flex md6>
                        <v-autocomplete
                          v-model="addjor18.positions"
                          :items="userstatus"
                          item-text="user_status_name"
                          item-value="user_status_name"
                          label="ตำแหน่ง"
                          required
                          :rules="[v => !!v || '']"
                        ></v-autocomplete>                       
                      </v-flex>
                    </v-flex>                   
                  </v-layout>
                </v-container>              
              </v-form>
            </v-card-text>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn
                color="warning"
                large
                @click.stop="addjor18dialog = false"
                rounded
                ><v-icon dark>mdi-close</v-icon> ยกเลิก</v-btn
              >
              <v-btn
                large
                color="success"
                @click.stop="addjor18Submit()"
                rounded
              >
                <v-icon dark>mdi-content-save</v-icon>&nbsp;&nbsp;บันทึก
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
      </v-layout>

      <!-- V-model deletejor18dialog -->
      <v-layout>
        <v-dialog v-model="deletejor18dialog" persistent max-width="40%">
          <v-card class="mx-auto pa-5">
            <base-material-card
              color="error"
              icon="mdi-delete"
              title="ลบข้อมูลประเภทสถานศึกษา"
              class="px-5 py-3"
            >
            </base-material-card>

            <v-card-text class="text_google">
              <v-card>
                <v-card-text>
                  <v-form ref="deletejor18form" lazy-validation>
                    <v-container grid-list-md>
                      <v-layout wrap>
                        <v-flex xs12>
                          ยืนยันการลบข้อมูล <br />
                          {{
                            editjor18.college_name +
                              " : " +
                              editjor18.id_position
                          }}
                        </v-flex>
                      </v-layout>
                    </v-container>
                  </v-form>
                </v-card-text>
              </v-card>
            </v-card-text>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn large @click.stop="deletejor18dialog = false"
                ><v-icon dark>mdi-close</v-icon>ยกเลิก</v-btn
              >
              <v-btn
                large
                color="red darken-3"
                @click.stop="deletejor18Submit()"
                dark
              >
                <v-icon dark>mdi-delete</v-icon>&nbsp;ลบ
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
      </v-layout>

      <!-- V-model editjor18dialog -->
      <v-layout row justify-center>
        <v-dialog v-model="editjor18dialog" persistent max-width="50%">
          <v-card class="mx-auto pa-6">
            <base-material-card
              color="yellow"
              icon="mdi-clipboard-text"
              title="แก้ไขข้อมูล"
              class="px-5 py-3"
            ></base-material-card>
            <v-card-text>
              <v-form ref="editjor18form" lazy-validation>
                <v-container grid-list-md>             

                          <v-layout wrap>
                            <v-flex md12>
                    เลขที่ตำแหน่ง {{ editjor18.id_position }} : ตำแหน่ง {{ editjor18.positions }}
                    </v-flex>
                    <v-flex md6>
                      <v-autocomplete
                      outlined
                        :items="colleges"
                        item-text="college_name"
                        item-value="college_code"
                        v-model="editjor18.j18college_code"
                        label="วิทยาลัย"
                        required
                        :rules="[v => !!v || '']"
                      >
                      </v-autocomplete>
                    </v-flex>
                    <v-flex md6>
                      <v-autocomplete
                      outlined
                        :items="reasons"
                        item-text="text"
                        item-value="value"
                        v-model="editjor18.j18_reason"
                        label="เหตุผล"
                        required
                        :rules="[v => !!v || '']"
                      >
                      </v-autocomplete>
                    </v-flex>

                    <v-flex md12>
                      <v-textarea
                      v-if="editjor18.j18_reason==='etc'"
                      outlined                        
                        v-model="editjor18.j18_reason_etc"
                        label="เหตุผลอื่นๆ"
                        required
                        :rules="[v => !!v || '']"
                      >
                      </v-textarea>
                    </v-flex>
                                         
                  </v-layout>
                </v-container>
                <small>* จำเป็น</small>
              </v-form>
            </v-card-text>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn large @click.stop="editjor18dialog = false" rounded>
                <v-icon dark>mdi-close</v-icon>ยกเลิก
              </v-btn>
              <v-btn
                large
                color="warning"
                @click.stop="editjor18Submit()"
                rounded
              >
                <v-icon dark>mdi-pencil</v-icon>&nbsp;บันทึก
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
      </v-layout>
    </v-container>

    <v-container fluid>
      <v-snackbar
        v-model="snackbar.show"
        top
        :timeout="snackbar.timeout"
        :color="snackbar.color"
      >
        <v-icon large>{{ snackbar.icon }}</v-icon>
        <v-card-text>
          {{ snackbar.text }}
        </v-card-text>

        <template v-slot:action="{ attrs }">
          <v-btn text v-bind="attrs" @click="snackbar.show = false"
            >Close</v-btn
          >
        </template>
      </v-snackbar>
    </v-container>
  </div>
</template>
<script>

import personnel_bar from "../../components/admin/personnel_bar.vue";

export default {
  components: { personnel_bar },
  data() {
    return {
      loading: true,
      ApiKey: "HRvec2021",
      valid: true,
      addjor18dialog: false,
      editjor18dialog: false,
      deletejor18dialog: false,
      snackbar: {
        show: false,
        color: "",
        timeout: 5000,
        icon: "",
        text: ""
      },
      jor18s: [],
      addjor18: {},
      editjor18: {},
      colleges: [],
      userstatus: [],
      search: "",
      pagination: {},
      headers: [
        { text: "สถานศึกษา", align: "left", value: "j18college_code" },
        { text: "สถานศึกษา", align: "left", value: "college_name" },
        { text: "จังหวัด", align: "left", value: "province_name" },
        { text: "ภาค", align: "left", value: "region_name" },
        { text: "อัตราว่าง", align: "left", value: "num_position" },
        { text: "รหัสตำแหน่ง", align: "left", value: "id_position" },
        { text: "รหัสบัตรประชาชน", align: "left", value: "id_card" },
        { text: "คำนำหน้า", align: "left", value: "titles" },
        { text: "ชื่อ", align: "left", value: "first_name" },
        { text: "นามสกุล", align: "left", value: "last_name" },
        { text: "ตำแหน่ง", align: "left", value: "positions" },
        { text: "วิทยฐานะ", align: "left", value: "rang_positon" },
        { text: "หมายเหตุ", align: "left", value: "ptdel_status_del" },
        { text: "เหตุการณ์", align: "left", value: "j18_background" },        
        { text: "การนำมาใช้", align: "center", value: "j18_reason" },
        { text: "เหตุผลอื่น", align: "left", value: "j18_reason_etc" },
        {
          text: "แก้ไข",
          align: "center",
          value: "actions",
          icon: "mdi-file-document-edit"
        },
        {
          text: "ลบ",
          align: "center",
          value: "action_s",
          icon: "mdi-delete-forever"
        }
      ],
      rowsperpage: [
        25,
        50,
        100,
        {
          text: "All",
          value: -1
        }
      ],

      college: {},
      provinces: [],
      prefectures: [],
      collgegs: [],
      jor18status: [],
      regions: [],
      region_ena: true,
      data_syslog: {},
      collegesSelect: "",
      idPositionSelect:"",
      reasons:[
        {text:'คัดเลือก',value:'คัดเลือก'},
        {text:'สอบแข่งขัน',value:'สอบแข่งขัน'},
        {text:'ย้าย',value:'ย้าย'},
        {text:'อื่นๆ',value:'etc'},
      ],
      updatemanpowers:{},
    };
  },
  async mounted() {
    await this.sweetAlertLoading() 
    let result;
    result = await this.$http.post("college.php", {
      ApiKey: this.ApiKey
    });

    this.colleges = result.data;
    result = await this.$http.post("province.php", {
      ApiKey: this.ApiKey
    });
    this.provinces = result.data;

    result = await this.$http.post("region.php", {
      ApiKey: this.ApiKey
    });
    this.regions = result.data;

    let user_status_result;
    user_status_result = await this.$http.post(
      "crud_user_status.php?crud=read",
      {
        ApiKey: this.ApiKey
      }
    );
    this.userstatus = user_status_result.data;
    Swal.close();
  },

  methods: {
    async updatej18background(){
      this.updatemanpowers.ApiKey = this.ApiKey;
        let result = await this.$http.post("man_power.insert.j18.php", this.updatemanpowers);
        
        if (result.data.status == true) {         
           Swal.fire({
            icon: "success",
            title: "ปรับปรุงข้อมูลอัตรากำลังรับย้ายเรียบร้อย",
            showConfirmButton: false,
            timer: 1500
          });
        }

    },
    async SearchNull(){
      await this.sweetAlertLoading()
      this.loading = true;
      let result = await this.$http
        .post("jor18.php", {
          ApiKey: this.ApiKey,
          idpostion_null: 'ok'
        })
        .finally(() => (this.loading = false));
      this.jor18s = result.data;
      Swal.close();
    },

    async clearSearch(){
      this.jor18s=[]
      this.collegesSelect=''
      this.idPositionSelect=''
    },
    async jor18sSearch() {
      this.loading = true;
      let result = await this.$http
        .post("jor18.php", {
          ApiKey: this.ApiKey,
          j18college_code: this.collegesSelect
        })
        .finally(() => (this.loading = false));
      this.jor18s = result.data;
      
    },

    async jor18sIDpostionSearch() {
      this.loading = true;
      let result = await this.$http
        .post("jor18.php", {
          ApiKey: this.ApiKey,
          id_position: this.idPositionSelect
        })
        .finally(() => (this.loading = false));
      this.jor18s = result.data;
      
    },


    async jor18QueryAll() {
      this.loading = true;
      let result = await this.$http
        .post("jor18.php", {
          ApiKey: this.ApiKey
        })
        .finally(() => (this.loading = false));
      this.jor18s = result.data;
    },
    async jor18Add() {
      this.addjor18 = {};
      this.addjor18dialog = true;
    },
    async addjor18Submit() {
      if (this.$refs.addjor18form.validate()) {
        this.addjor18.ApiKey = this.ApiKey;
        this.addjor18.id_card = "";
        let result = await this.$http.post("jor18.insert.php", this.addjor18);
        
        if (result.data.status == true) {
          this.jor18 = result.data;
          this.snackbar.icon = "mdi-font-awesome";
          this.snackbar.color = "success";
          this.snackbar.text = "บันทึกข้อมูลเรียบร้อย";
          this.snackbar.show = true;
          let userSession = JSON.parse(sessionStorage.getItem("user")) || 0;
          this.data_syslog.ApiKey = this.ApiKey;
          this.data_syslog.user_account = userSession.user_name;
          this.data_syslog.event_log = "insert";
          this.data_syslog.page_log = "jor18";
          this.data_syslog.table_log = "jor18";
          this.data_syslog.detail_log =
            this.addjor18.id_position + ":" + this.addjor18.college_code;
          this.data_syslog.date_times = this.date_today_log;
          await this.$http.post("data_syslog.insert.php", this.data_syslog);

          this.jor18QueryAll();
        } else {
           Swal.fire({
            icon: "warning",
            title: "บันทึกข้อมูลผิดพลาด",
            showConfirmButton: false,
            timer: 1500
          });
          this.jor18QueryAll();
        }
        this.addjor18dialog = false;
      }
    },

    async jor18Edit(id_position,positions) {
      let result = await this.$http.post("jor18.php", {
        ApiKey: this.ApiKey,
        id_position:id_position,
        positions:positions,
      });
      this.editjor18 = result.data;
      this.editjor18dialog = true;
    },
    async editjor18Submit() {
      if (this.$refs.editjor18form.validate()) {
        this.editjor18.ApiKey = this.ApiKey;
        let result = await this.$http.post("jor18.updateIdPosition.php", this.editjor18);
        if (result.data.status == true) {
          this.jor18 = result.data;
           Swal.fire({
            icon: "success",
            title: "แก้ไขข้อมูลเรียบร้อย",
            showConfirmButton: false,
            timer: 1500
          });
          let userSession = JSON.parse(sessionStorage.getItem("user")) || 0;
          this.data_syslog.ApiKey = this.ApiKey;
          this.data_syslog.user_account = userSession.user_name;
          this.data_syslog.event_log = "update";
          this.data_syslog.page_log = "jor18";
          this.data_syslog.table_log = "jor18";
          this.data_syslog.detail_log =
            this.editjor18.id_position +
            " : " +
            this.editjor18.college_code +
            " : " +
            this.editjor18.case_vacancy;
          this.data_syslog.date_times = this.date_today_log;
          await this.$http.post("data_syslog.insert.php", this.data_syslog);

          this.jor18QueryAll();
        } else {
           Swal.fire({
            icon: "warning",
            title: "แก้ไขข้อมูลผิดพลาด",
            showConfirmButton: false,
            timer: 1500
          });
        }
        this.editjor18dialog = false;
      }
    },
    async jor18Delete(id_position,positions) {
      let result = await this.$http.post("jor18.php", {
        ApiKey:this.ApiKey,
        id_position:id_position,
        positions:positions,
      });
      this.editjor18 = result.data;    
      this.deletejor18dialog = true;
    },
    async deletejor18Submit() {
      if (this.$refs.deletejor18form.validate()) {
        this.editjor18.ApiKey = this.ApiKey;
        let result = await this.$http.post("jor18.delete.php", this.editjor18);
        
        if (result.data.status == true) {
          this.jor18 = result.data;
          Swal.fire({
            icon: "success",
            title: "ดำเนินการข้อมูลเรียบร้อย",
            showConfirmButton: false,
            timer: 1500
          });
          let userSession = JSON.parse(sessionStorage.getItem("user")) || 0;
          this.data_syslog.ApiKey = this.ApiKey;
          this.data_syslog.user_account = userSession.user_name;
          this.data_syslog.event_log = "delete";
          this.data_syslog.page_log = "jor18";
          this.data_syslog.table_log = "jor18";
          this.data_syslog.detail_log =
            this.editjor18.id_position +
            " : " +
            this.editjor18.college_code +
            " : " +
            this.editjor18.case_vacancy;
          this.data_syslog.date_times = this.date_today_log;
          await this.$http.post("data_syslog.insert.php", this.data_syslog);
          this.jor18QueryAll();
        } else {
          Swal.fire({
            icon: "warning",
            title: "ดำเนินการผิดพลาด",
            showConfirmButton: false,
            timer: 1500
          });
        }
        this.deletejor18dialog = false;
      }
    },
    async sweetAlertLoading() {
          Swal.fire({
        title: "Please Wait !",
        timerProgressBar: true,
        didOpen: () => {
          Swal.showLoading();         
        },
        onBeforeOpen: () => {
          Swal.showLoading();
        },
        onAfterClose: () => {
          Swal.hideLoading();
        },
        allowOutsideClick: false,
        allowEscapeKey: false,
        allowEnterKey: false
      });
    },

  },
  computed: {
    pages() {
      if (
        this.pagination.rowsPerPage == null ||
        this.pagination.totalItems == null
      )
        return 0;

      return Math.ceil(
        this.pagination.totalItems / this.pagination.rowsPerPage
      );
    },
    date_today_log() {
      let today = new Date();
      let dd = String(today.getDate()).padStart(2, "0");
      let mm = String(today.getMonth() + 1).padStart(2, "0"); //January is 0!
      let yyyy = today.getFullYear() + 543;
      let time =
        today.getHours() + ":" + today.getMinutes() + ":" + today.getSeconds();
      today = dd + "/" + mm + "/" + yyyy + "/" + time;
      return today;
    }
  }
};
</script>
